<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useQuizStore } from "../stores/useQuizStore";
import { checkmarkOutline, closeOutline } from "ionicons/icons";
const router = useRouter();
const loading = ref(true);
const quizStore = useQuizStore();
const quizState = quizStore.quizState;
const animateClass = ref("");
const currentQuestion = ref(null);
const checklistFromApi = ref([
  {
    id: 1,
    title:
      "I prodotti sono disposti in modo attraente e accessibile, e l'allestimento del punto vendita è aggiornato secondo le ultime direttive visual merchandising?",
    answer: false,
  },
  {
    id: 2,
    title:
      "Le scorte sono adeguatamente monitorate e gestite, con un processo efficace per il riassortimento dei prodotti per evitare situazioni di overstock o esaurimento scorte?",
    answer: false,
  },
  {
    id: 3,
    title:
      "Il negozio è pulito, ben organizzato e conforme agli standard di sicurezza e igiene, offrendo un ambiente accogliente e sicuro sia per i clienti che per i dipendenti?",
    answer: false,
  },
  {
    id: 4,
    title:
      "Il personale è ben informato sui prodotti, motivato e adeguatamente formato per offrire un servizio clienti eccellente e raggiungere gli obiettivi di vendita?",
    answer: false,
  },
  {
    id: 5,
    title:
      "Sono stati raccolti e analizzati i feedback dei clienti per migliorare l'esperienza di acquisto e sono state implementate azioni correttive in base a tali feedback?",
    answer: false,
  },
]);

onMounted(() => {
  startQuiz();
  loading.value = false;
});

function setAnimateClass(action) {
  switch (action) {
    case "enter":
      animateClass.value = "animate__animated animate__bounceInRight";
      break;
    case "leave":
      animateClass.value = "animate__animated animate__backOutLeft";
      break;
    default:
      animateClass.value = "animate__animated";
      break;
  }
}

function startQuiz() {
  quizStore.setCurrentQuiz([...checklistFromApi.value]);
  getCurrentQuestion();
}

function getCurrentQuestion() {
  currentQuestion.value = quizStore.currentQuestion;
  setAnimateClass("enter");
}

function handleAnswer(answer) {
  setAnimateClass("leave");
  quizStore.nextQuestion(answer);
  setTimeout(() => {
    getCurrentQuestion();
  }, 100);
}

function ricomincia() {
  quizStore.restart();
}

function inviaQuiz() {
  router.go(-1);
}
</script>

<template>
  <master-layout smallTitle="la tua" bigTitle="Checklist">
    <h6 class="mb-4">
      Valuta pulizia, organizzazione, sicurezza, team e soddisfazione cliente in
      modo efficace e rapido.
    </h6>

    <div
      v-if="
        quizStore.quizState === 'ready' &&
        currentQuestion &&
        currentQuestion.title
      "
      class="mt-4"
    >
      <ion-card class="shadow-4 animate__animated" :class="animateClass">
        <ion-card-content>
          <div
            class="w-full d-flex flex-column justify-content-center align-items-center"
          >
            <span class="mb-2">{{ currentQuestion.title }}</span>
            <div
              class="w-full d-flex justify-content-around align-items-center mt-4"
            >
              <button
                @click="handleAnswer(false)"
                class="p-d button-secondary shadow-2 d-flex justify-content-center align-items-center"
              >
                <ion-icon
                  :icon="closeOutline"
                  style="font-size: 1.5rem"
                ></ion-icon>
                <span class="ml-2">No</span>
              </button>
              <button
                @click="handleAnswer(true)"
                class="p-d button-secondary shadow-2 d-flex justify-content-center align-items-center"
              >
                <ion-icon
                  :icon="checkmarkOutline"
                  style="font-size: 1.5rem"
                ></ion-icon>
                <span class="ml-2">Sì</span>
              </button>
            </div>
          </div>
        </ion-card-content>
      </ion-card>
    </div>
    <ion-card class="mt-2">
      <ion-card-content
        v-if="quizStore.quizState === 'finished'"
        class="w-full d-flex flex-column justify-content-center align-items-center"
      >
        <div
          v-for="question in quizStore.currentQuiz"
          :key="question.id"
          class="w-full d-flex justify-content-start align-items-center mb-4"
        >
          <span class="mb-2">
            {{
              question.title.length > 50
                ? question.title.slice(0, 50) + "..."
                : question.title
            }}
          </span>
          <span>
            <ion-icon
              :icon="question.answer ? checkmarkOutline : closeOutline"
              style="font-size: 1.5rem"
            ></ion-icon>
          </span>
        </div>
      </ion-card-content>
    </ion-card>

    <div
      v-if="quizStore.quizState === 'finished'"
      class="w-full d-flex justify-content-center align-items-center"
    >
      <button @click="ricomincia" class="p-d button-secondary shadow-2">
        Rispondi nuovamente
      </button>
      <button @click="inviaQuiz" class="p-d button-primary shadow-2 ml-2">
        Invia
      </button>
    </div>
  </master-layout>
</template>
