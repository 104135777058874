import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const useQuizStore = defineStore("quiz", () => {
  const _currentQuiz = ref([]);
  const _currentQuestionIndex = ref(0);
  const _quizState = ref("ready");

  const currentQuiz = computed(() => _currentQuiz.value);
  const currentQuestion = computed(() => {
    return _currentQuiz.value[_currentQuestionIndex.value];
  });
  const currentQuestionIndex = computed(() => _currentQuestionIndex.value);
  const quizState = computed(() => _quizState.value);

  function setCurrentQuiz(quiz) {
    _currentQuiz.value = quiz;
  }

  function nextQuestion(answer) {
    if (_currentQuestionIndex.value >= _currentQuiz.value.length - 1) {
      _currentQuiz.value[_currentQuestionIndex.value].answer = answer;
      _quizState.value = "finished";
      return;
    }
    _currentQuiz.value[_currentQuestionIndex.value].answer = answer;
    _currentQuestionIndex.value++;
  }

  function restart() {
    _currentQuestionIndex.value = 0;
    _quizState.value = "ready";
  }

  return {
    currentQuiz,
    currentQuestion,
    currentQuestionIndex,
    quizState,
    setCurrentQuiz,
    nextQuestion,
    restart,
  };
});
